import { v4 as uuidv4 } from 'uuid';
//import React, { useEffect, useState } from 'react';
import axios from 'axios';
let socket;
let currentResponse = '';
let userInfoState=false;

export async function initializeWebSocket(setMessages, setIsTyping) {
   
    try {
        const conversationId = localStorage.getItem('ConvId') || uuidv4();
        localStorage.setItem('ConvId', conversationId);
        //socket = new WebSocket(`ws://localhost:8081/api/be-bot/ui/chat/${conversationId}`);
        //console.log("ENV:", process.env.REACT_APP_LOCAL_URL);
        socket = new WebSocket(`wss://${window.location.hostname}/api/be-bot/ui/chat/${conversationId}`);
        socket.onmessage = function (event) {
            const data = JSON.parse(event.data);
            if (data) {
                console.log(data);
                const newMessage = {
                    id: data.id,
                    sender: "bot",
                    message: data.message,
                    timestamp: new Date()
                };
                setMessages((prevMessages) => [...prevMessages, newMessage]);
                setIsTyping(false);
            }
            if (data.error) {
                //appendMessage('Assistant', `Error: ${data.error}`, true);
                currentResponse = '';
                // hideTypingIndicator();
                return;
            }
        };
 
        socket.onopen = function () { 
            const messageInfo = {
                id: uuidv4(),
                sender: 'user',
                message: 'faq',
                time: Math.floor(Date.now() / 1000)
            }
            sendMessage(messageInfo);
        };
 
        socket.onerror = function (error) {
            console.error('WebSocket error:', error);
            //appendMessage('System', 'Connection error occurred', true);
        };
 
        socket.onclose = function () {
            console.log('WebSocket disconnected');
            //setTimeout(initializeWebSocket, 3000); // Attempt to reconnect
        };
    } catch (error) {
        console.error('Failed to initialize WebSocket:', error);
        //appendMessage('System', 'Failed to establish connection', true);
    }
}

export function sendMessage(message) {
    if (socket && socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify(message));

        if(userInfoState===false){
            console.log(localStorage.getItem('ConvId'))
            console.log("email support")
            let conversationId=localStorage.getItem('ConvId');
            // axios.get(`http://127.0.0.1:8081/api/be-bot/user-info`, {
            //   params: { 
            //   conversation_id: conversationId },  // Sending conversation_id as a query parameter
            // })
            axios.get(`https://${window.location.hostname}/api/be-bot/user-info`, {
                params: {
                  conversation_id: conversationId
                },
              })              
            .then((response) => {
            // setData(response.data);  // Set the response data
               console.log(response.data, response.data.data.name, response.data.data.email);
               if(response.data.data.name === "" && response.data.data.email === ""){
                  console.log("Name and email is empty");
                  localStorage.setItem('userId', response.data.data.id);
                  localStorage.setItem('name', "");
                  localStorage.setItem('email', "");
                  userInfoState=true;
            }
           })
        .catch((error) => {
             //setError(error.message);  // Handle any error
             console.log(error);         
          });
       }
       

    } else {
        console.error('WebSocket is not open. Ready state:', socket.readyState);
    }
}